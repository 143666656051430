import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  apiServerUrl:String=environment.apiURL + "/user";
  apiServerUrlToken:String=environment.apiURL  + "/auth";

  constructor(private http: HttpClient ) { }

  permissionsList:any;

  setGlobalPermissions(permissions:any){
    this.permissionsList = permissions;
  }

  getGlobalPermissions(){
    return this.permissionsList;
  }

  addDepartment(request: any) {
    return this.http.post<any>(this.apiServerUrl + "/department", request);
  }

  getDepartmentList() {
    return this.http.get<any>(this.apiServerUrl + "/department")
  }

  getDesignation(departmentId:any) {
    return this.http.get<any>(this.apiServerUrl + "/designation/"+departmentId)
  }

  addDesignation(request: any) {
    return this.http.post<any>(this.apiServerUrl + "/designation", request)
  }

  getAllRoles() {
    return this.http.get<any>(this.apiServerUrl + "/role")
  }

  addRoles(request: any) {
    return this.http.post<any>(this.apiServerUrl + "/role", request);
  }

  addInternalUser(request: any) {
    return this.http.post<any>(this.apiServerUrl + "/internal-user", request)
  }

  getInternalUser() {
    return this.http.get<any>(this.apiServerUrl + "/internal-user")
  }

  addModule(request: any) {
    return this.http.post<any>(this.apiServerUrl + "/module", request)
  }

  getModule(){
    return this.http.get<any>(this.apiServerUrl + "/module")
  }

  addPermission(request:any){
    return this.http.post<any>(this.apiServerUrl + "/permission", request)
  }

  getPermission(){
    return this.http.get<any>(this.apiServerUrl + "/permission")
  }


  getUserPermission(userId:any){
    return this.http.get<any>(this.apiServerUrl + "/user-permission/"+userId)
  }

  updateUserPermission(requst:any){
    return this.http.put<any>(this.apiServerUrl + "/update-user",requst)
  }

  addRolePermission(request:any){
    return this.http.post<any>(this.apiServerUrl + "/role/permissions",request)
  }

  getRolePermission(roleId:any){
    return this.http.get<any>(this.apiServerUrl + "/role/permissions/"+roleId)
  }

  userStatus(userId:any, status:any){
    return this.http.put<any>(this.apiServerUrl + "/status/"+userId+"/"+status ,userId, status)
  }

  login(body:any){
    return this.http.post<any>(this.apiServerUrl + "/login",body)
  }


  loginWithAuthToken(body:any){
    return this.http.post<any>(this.apiServerUrlToken + "/signin",body)
  }

  refreshToken(body:any){
      return this.http.post<any>(this.apiServerUrlToken + "/refresh-token",body)
  }

  forgetPassword(body:any){
    return this.http.put<any>(this.apiServerUrl + "/recover-password" ,body)
  }


  resetPassword(body:any){
    return this.http.post<any>(this.apiServerUrl + "/reset-password",body)
  }

  getResetemailToken(token:any){
    return this.http.get<any>(this.apiServerUrl + "/email/"+token)
  }

  /////////////////// add party /////////////////

  addParty(body:any){
    return this.http.post<any>(this.apiServerUrl + "/party",body)
  }

  addPartyUser(body:any){
    return this.http.post<any>(this.apiServerUrl + "/party-user",body)
  }

  getUserPartyType(userType:any){
    return this.http.get<any>(this.apiServerUrl + "/party/"+userType)
  }

  getUserPartyById(id:any){
    return this.http.get<any>(this.apiServerUrl + "/party/users/"+id)
  }


}
