import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, filter, finalize, switchMap, take, tap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { EncryptionService } from './encryption.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../modules/auth/services/auth.service';
import { SettingsService } from './settings.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  auth: any;
  GenerateOn:Date;
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(private router : Router, private encryptionService :EncryptionService,
    private inj: Injector, private authService: AuthService, private settingService:SettingsService) {
    }

  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   let tokenDetails= localStorage.getItem("authToken") ? JSON.parse(this.encryptionService.decryptUsingAES256(localStorage.getItem("authToken") || '{}')):null;
  //     let access_id = tokenDetails;
  //     console.log("text")
  //   if (!request.url.toLocaleLowerCase().includes('/signin')){
  //     request = this.addTokenToRequest(request);
  //     }
  //    return next.handle(request).pipe(catchError(err => {
  //       if (err.status==401 && access_id) {
  //         return this.settingService.refreshToken({token:access_id}).pipe(
  //           switchMap(response => {
  //             console.log( response)
  //             const newRequest = request.clone({
  //               setHeaders: { Authorization: `Bearer ${response.refreshToken}` }
  //             });
  //             return next.handle(newRequest);
  //           }),
  //           catchError(err => {
  //             return throwError(err);
  //           })
  //         );
  //       }
      
  //       return throwError(err);
  //   }))
  // }
//   addTokenToRequest(request: HttpRequest<any>){
//     let tokenDetails= localStorage.getItem("authToken") ? JSON.parse(this.encryptionService.decryptUsingAES256(localStorage.getItem("authToken") || '{}')):null;
//       let access_id = tokenDetails;
//         let token = "Bearer " +access_id;
//         if (token) {
//             request = this.updateRequest(request, token);
//         } else {
//             request = this.updateRequest(request);
//         }
//         return request;
// }

//   updateRequest(request: HttpRequest<any>, access_id?:any) {
//     request = request.clone({
//         headers: this.addExtraHeaders(request.headers, access_id)
//     });
//     return request;
// }
// addExtraHeaders(headers: HttpHeaders, access_id:any): HttpHeaders {
//     if (access_id) {
//         headers = headers.append('Authorization', `${access_id}`);
//     }
//     return headers;
// }


intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 if(request.url.toLocaleLowerCase().startsWith(environment.apiURL.toLocaleLowerCase()) ) {
  const modifiedRequest = this.addAuthorizationHeader(request);

  return next.handle(modifiedRequest).pipe(
    catchError(error => {
      if (error.status === 401) {
        return this.handleUnauthorizedError(request, next);
      }

      return throwError(error);
    })
  );
 }
 return next.handle(request)

}

private addAuthorizationHeader(request: HttpRequest<any>,token?:any): HttpRequest<any> {
  let access_id = null;
  if(token)
     access_id = token;
  else
    access_id= localStorage.getItem("authToken") ? JSON.parse(this.encryptionService.decryptUsingAES256(localStorage.getItem("authToken") || '{}')):null;  
  if (access_id) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${access_id}`
      }
    });
  }
  return request;
}

private handleUnauthorizedError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  if (!this.isRefreshing) {
    this.isRefreshing = true;
    this.refreshTokenSubject.next(null);
    return this.authService.refreshAccessToken().pipe(
      switchMap((token:any) => {
        this.isRefreshing = false;
        const newRequest = this.addAuthorizationHeader(request,token);
        return next.handle(newRequest);
      }),
      catchError(error => {
        // this.authService.logout();
        // return throwError('invalid token');
        const newRequest = this.addAuthorizationHeader(request);
        return next.handle(newRequest);
      })
    );
  }else{
    return this.authService.authTokenObservable.pipe(filter((token)=> token != null),take(1),switchMap((token)=>{
      const newRequest = this.addAuthorizationHeader(request,token);
      return next.handle(newRequest);
    }))
  }
}
}
